export default {
  first_name: '',
  last_name: '',
  email: '',
  city_interest: 'default',
  other_city: '',
  phone_one: '',
  agree_policy_privacy: false,
  marketing_campaign: '',
  coupon: '',
  origin: '',
  country: '',
  ip: '',
  ip_data: {},
  formErrors: [],
  share_link: '',
  share_link_discount: '',
}