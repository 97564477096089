import React from 'react';
import Flags from '../../../src/assets/images/flags.png';
import { TextField } from '@material-ui/core';
import ReactTelInput from 'react-telephone-input';


const VerifePhone = (
    {
        showVerify, 
        cell_phone, 
        verify_number, 
        handlePhone, 
        handleInputCode,
        handleShowVerify
    }
) => {
    return (
        <div className="flex justify-center items-center flex-col w-full">
            <div className="bg-white border border-gray-300 max-w-2xl">
                {showVerify === false ?
                    <div className="flex justify-center items-center flex-col p-8">
                        <ReactTelInput
                            required={true}
                            onChange={(telNumber) => handlePhone('cell_phone', telNumber)}
                            value={cell_phone}
                            defaultCountry="ve"
                            flagsImagePath={Flags}
                            className={(!cell_phone) ? 'telInputContact' : "telInputContact"}
                        />
                        {/* <div className="text-center color-light-gray text-base m-t10">
                            Servicio exclusivo para venezolanos fuera del país.
                        </div> */}
                    </div>
                    : 
                    <div className="flex flex-col p-8">
                        <div className="text-gray-800 text-base mb-4">
                            Ingresa el código que te hemos enviado por SMS
                        </div>
                        <div className="w-full">
                            <TextField
                                type="number"
                                name="verify_number"
                                placeholder="0000"
                                error={true}
                                value={verify_number}
                                onChange={handleInputCode}
                                variant="outlined"
                                className="wInputFull"
                                InputProps={{
                                classes: {
                                    notchedOutline: 'cssInputSelect',
                                },
                                }}
                            />
                        </div>
                    </div>
                }
            </div>
        {showVerify === false ?
          <button 
            className={`primary-button mt-16 ${!cell_phone && 'disabled'}`}
            disabled={!cell_phone ? true : false}
            onClick={() => handleShowVerify(true)}
          >
            Siguiente
          </button>
          : <button 
            className={`primary-button mt-16 ${!verify_number && 'disabled'}`}
            disabled={!verify_number ? true : false}
            onClick={() => handleShowVerify('finalizado')}
          >
            Siguiente
          </button>
        }
      </div>
    )
};

export default VerifePhone;