import React from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  progress: {
    margin: theme.spacing(1),
    color: '#fff',
  },
});

function LoaddingFullScreen(props) {
    const { classes } = props;
    return(
      <div className="w-screen h-screen z-99 flex fixed justify-center items-center bg-black bg-opacity-75 top-0 left-0 flex-col">
        <div className="m-auto flex flex-col items-center">
          <CircularProgress className={`progress ${classes.progress}`} size={50} thickness={3} />
          <span className="text-white container text-center text-base mt-4 leading-relaxed">{ props.menssage }</span>
        </div>
      </div>
    );
}

LoaddingFullScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoaddingFullScreen);