import React, { Component } from "react";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import PropTypes from "prop-types";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { withStyles } from "@material-ui/core/styles";
import ActionPointsTable from "./ActionPointsTable";

const styles = () => ({
  dialogPaper: {
    minWidth: "50vw",
  },
});

class PointTermsDialog extends Component {
  render() {
    const { open, handleClose, fullScreen, classes } = this.props;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        classes={{ paper: classes.dialogPaper }}
      >
        <style global jsx>
          {`
            .award-table {
              width: 100%;
              border-collapse: collapse;
            }

            .award-table th {
              background: #200844;
              color: white;
              padding: 10px 0;
            }

            .award-table td {
              padding: 10px;
            }
          `}
        </style>
        <DialogContent>
          <p className="my-4 font-bold text-center">
            TÉRMINOS Y CONDICIONES DEL PROGRAMA "BIG FAMILY"
          </p>
          <p className="my-4 my-8 text-justify">
            El programa "Big Family" (el "Programa") es un plan de fidelidad
            exclusivo para los Aliados Asistensi registrados y activos como
            Aliados Asistensi según los términos y condiciones del programa de
            Aliados Asistensi (los "Aliados").
          </p>
          <p className="my-4 text-justify">
            Participar en el Programa permitirá a los Aliados generar puntos y
            canjearlos por premios que estén disponibles en el inventario de
            premios del Programa a la fecha de la solicitud de canje, de
            conformidad con los siguientes términos y condiciones.
          </p>
          <p className="my-4 mt-8 font-bold text-center">
            TÉRMINOS Y CONDICIONES
          </p>
          <p>
            <span className="font-bold">1. Participación</span>
            <p className="my-4 text-justify">
              Se entiende que todos los Aliados estarán participando en el
              Programa por su condición de aliados, sin necesidad de afiliación
              o manifestación de voluntad expresa. En el caso de que un Aliado
              desee no participar en el Programa deberá pedir darse de baja como
              Aliado.
            </p>
          </p>
          <p>
            <span className="font-bold">2. Obtener puntos</span>
            <p className="my-4 text-justify">
              Todos los aliados recibirán puntos por las acciones que realicen
              de conformidad con la siguiente tabla:
            </p>

            <ActionPointsTable />

            <p className="my-4">
              Asistensi se reserva el derecho de modificar en cualquier momento
              la tabla descrita en el presente punto, lo cual hará notificando
              oportunamente a sus Aliados.
            </p>

            <p className="my-4">
              Asistensi hará sus mejores esfuerzos por mantener información
              veraz y actualizada del balance de puntos del Aliado, no obstante,
              podrá haber situaciones en las que el balance de puntos no refleje
              todas las acciones realizadas por el Aliado mientras los puntos
              son calculados.
            </p>
          </p>
          <p>
            <span className="font-bold">3. Canjear puntos</span>
            <p className="my-4 text-justify">
              Los puntos podrán ser canjeados únicamente por premios de
              conformidad con el catálogo de premios y valores de canje que esté
              vigente en el Módulo de Aliados. Asistensi se reserva el derecho
              de modificar en cualquier momento el catálogo de puntos y su valor
              de canje. En el momento del canje, Asistensi restará los puntos
              correspondientes del balance de puntos del Aliado.
            </p>
            <p className="my-4 text-justify">
              Asistensi entregará los premios en un tiempo prudencial, tomando
              en cuenta la ubicación geográfica del Aliado y la disponibilidad
              del producto.
            </p>
            <p className="my-4 text-justify">
              Asistensi se reserva el derecho a cambiar los premios por
              equivalentes conforme a la disponibilidad en el momento del canje.
            </p>
            <p className="my-4 text-justify">
              Los puntos no tendrán ningún un valor monetario o económico. En
              ningún caso un valor monetario o económico podrá ser pagado por
              puntos no usados, perdidos o no canjeados.
            </p>
          </p>
          <p>
            <span className="font-bold">4. Pérdida de los puntos</span>
            <p className="my-4 text-justify">
              Los aliados perderán la totalidad de sus puntos acumulados en su
              balance de puntos en los siguientes supuestos:
              <p>
                a) Que el Aliado no impulse y/o refiera una venta en un lapso de
                6 meses; o
              </p>
              <p>
                b) Que el Aliado pierda su condición de Aliado por cualquier
                motivo.
              </p>
            </p>
            <p className="my-4 text-justify">
              En el caso de la pérdida de puntos, el Aliado no podrá reclamar un
              valor o contraprestación de ningún tipo por los puntos perdidos.
            </p>
          </p>
          <p>
            <span className="font-bold">5. Fecha de inicio del Programa</span>
            <p className="my-4 text-justify">
              <p>El Programa estará vigente desde el 15 de Noviembre de 2020</p>
              Asistensi podrá terminar el Programa cuando lo estime conveniente
              a su entera discreción lo cual será notificado a los Aliados de
              manera oportuna.
            </p>
          </p>
        </DialogContent>
        <DialogActions>
          <button
            className="white-button-nav min-w-small"
            onClick={handleClose}
          >
            Cerrar
          </button>
        </DialogActions>
      </Dialog>
    );
  }
}

PointTermsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog({ breakpoint: "xs" })(
  withStyles(styles)(PointTermsDialog)
);
