import React, {Component} from 'react';

class ActionPointsTable extends Component {
  render() {
    return (
      <div>
        <style global jsx>
          {`
            .award-table {
              width: 100%;
              border-collapse: collapse;
            }
            
            .award-table th {
              background: #200844;
              color: white;
              padding: 5px 0;
              border: 1px #f3f3f3 solid;
            }
            
            .award-table td {
              padding: 10px;
              border: 1px #999999 solid;
            }
            
            .award-table td.td-action > sup {
              font-size: 10px;
            }
          `}
        </style>
        <table className="award-table" border="1" cellPadding="0" cellSpacing="0">
          <tr>
            <th className="max-w-3xl text-center">Acción</th>
            <th className="text-center">Puntos ganados</th>
          </tr>
          <tr>
            <td className="td-action">Impulso de la venta de una póliza&nbsp;<sup>(1)</sup></td>
            <td className="text-center">40</td>
          </tr>
          <tr>
            <td className="td-action">Si el cliente que referiste renueva su póliza transcurrido un año&nbsp;<sup>(2)</sup></td>
            <td className="text-center">80</td>
          </tr>
          <tr>
            <td className="td-action">Traer otro aliado a la familia asistensi que impulse la venta de al menos una póliza&nbsp;<sup>(3)</sup></td>
            <td className="text-center">80</td>
          </tr>
          {/*<tr>
            <td>Invitación de otro aliado y que el mismo venda al menos una póliza (para aliados secundarios)</td>
            <td className="text-center">80</td>
          </tr>*/}
          <tr>
            <td>Creación del hipervínculo personal</td>
            <td className="text-center">10</td>
          </tr>
        </table>
        <div className="text-xs mt-4">
          <div>
            (1) En el caso de que la póliza sea de un cliente referido por ti, generará 40 puntos;
            si es de un cliente referido por otro aliado de tu red, generará 10 puntos.
          </div>
          <div>
            (2) En el caso de que la póliza sea de un cliente referido por ti, generará 80 puntos;
            si es de un cliente referido por otro aliado de tu red, generará 20 puntos.
          </div>
          <div>
            (3) Para Aliados Maestros, mediante la creación del nuevo aliado en el módulo de aliados;
            para el resto de aliados, mediante la presentación del nuevo aliado a la Gerencia de Red de
            Aliados para que se le dé el alta.
          </div>
        </div>
      </div>
    );
  }
}

export default ActionPointsTable;