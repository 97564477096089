import React, {Component} from 'react';
import {Dialog, DialogActions, DialogContent} from '@material-ui/core';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {withStyles} from '@material-ui/core/styles';
import ActionPointsTable from './ActionPointsTable';

const styles = () => ({
  dialogPaper: {
    minWidth: '50vw',
  },
});

class AllyPointsDialog extends Component {
  render() {
    const { open, handleClose, fullScreen, classes, handleShowTerms } = this.props;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent>
          <ActionPointsTable />
          <div className="mt-4">
            <span
              className="text-pink-500 font-bold underline cursor-pointer"
              onClick={handleShowTerms}
            >
              Consulta los términos y condiciones de este plan
            </span>
          </div>
        </DialogContent>
        <DialogActions>
          <button className="secondary-button-nav min-w-small" onClick={handleClose}>
            Entendido
          </button>
        </DialogActions>
      </Dialog>
    );
  }
}

AllyPointsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog({ breakpoint: "xs" })(
  withStyles(styles)(AllyPointsDialog)
);
